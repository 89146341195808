import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { token } from "../../../Hooks/UserHooks";
import { APP_CONFIG } from '../../../config';
import { toast } from 'react-toastify';
import { Container, Row, Col, Form, Button, Table, Modal, Spinner } from 'react-bootstrap';
import { FaEdit, FaTrash, FaEye } from 'react-icons/fa';

const Developer = () => {
  const [developers, setDevelopers] = useState([]);
  const [name, setName] = useState('');
  const [editingDeveloper, setEditingDeveloper] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalDeveloper, setModalDeveloper] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchDevelopers();
  }, []);

  const fetchDevelopers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${APP_CONFIG.backendUrl}api/developer`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDevelopers(response.data);
    } catch (error) {
      console.error('Error fetching developers:', error);
      toast.error('Error fetching developers. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingDeveloper) {
        await axios.put(`${APP_CONFIG.backendUrl}api/developer/${editingDeveloper._id}`, 
          { name },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        toast.success('Developer updated successfully');
      } else {
        await axios.post(`${APP_CONFIG.backendUrl}api/developer`, 
          { name },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        toast.success('Developer added successfully');
      }
      setName('');
      setEditingDeveloper(null);
      fetchDevelopers();
    } catch (error) {
      console.error('Error saving developer:', error);
      toast.error('Error saving developer. Please try again.');
    }
  };

  const handleEdit = (developer) => {
    setEditingDeveloper(developer);
    setName(developer.name);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this developer?')) {
      try {
        await axios.delete(`${APP_CONFIG.backendUrl}api/developer/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success('Developer deleted successfully');
        fetchDevelopers();
      } catch (error) {
        console.error('Error deleting developer:', error);
        toast.error('Error deleting developer. Please try again.');
      }
    }
  };

  const handleView = (developer) => {
    setModalDeveloper(developer);
    setShowModal(true);
  };

  return (
    <Container>
      <h2 className="my-4">Manage Developers</h2>
      <Form onSubmit={handleSubmit} className="mb-4">
        <Row>
          <Col md={8}>
            <Form.Group controlId="developerName" style={{marginBottom:"0"}}>
              <Form.Control
                type="text"
                placeholder="Enter developer name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                style={{margin:"0"}}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Button type="submit" variant="primary"                 style={{margin:"0"}}
            >
              {editingDeveloper ? 'Update Developer' : 'Add Developer'}
            </Button>
          </Col>
        </Row>
      </Form>

      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {developers.map((developer) => (
              <tr key={developer._id}>
                <td>{developer.name}</td>
                <td>
                  <Button variant="warning" size="sm" onClick={() => handleEdit(developer)} style={{marginRight:"10px"}}>
                    <FaEdit />
                  </Button>
                  <Button variant="danger" size="sm" onClick={() => handleDelete(developer._id)}>
                    <FaTrash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Developer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalDeveloper && (
            <p><strong>Name:</strong> {modalDeveloper.name}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Developer;
