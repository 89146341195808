import React from "react";
import "./subPage.css";
import { useEffect, useState, useReducer } from "react";
import axios from "axios";
import { token } from "../../../Hooks/UserHooks";
import CategoryCard from "../../CategoryCard/CategoryCard";
import SubForm from "./SubForm";
import { showToast } from "../../../Utils/showToast";
import dltIcon from "../../../Assets/deleteIcon.svg";
import editIcon from "../../../Assets/editIcon.svg";
import { Button } from "react-bootstrap";
import { APP_CONFIG } from "../../../config";

const Subpage = () => {
  const baseUrl = `${APP_CONFIG.backendUrl}api/suboffplan`;
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [names, setNames] = useState([]);
  const [offplanId, setOffplanId] = useState("");
  const [refresh, setRefresh] = useReducer((x) => x + 1, 0);
  const [images, setImages] = useState([]);
  const [dropdownName, setDropdownName] = useState("Choose offplan project");
  const [editImages, setEditImages] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false)
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  let initialValue = {
    offplan: offplanId,
    name: "",
    appartement_type: "",
    category: "",
    size: "",
    bedrooms: "",
    bathrooms: "",
    parkings: "",
    additional_features: "",
    furnished: "",
    description: "",
    status: "",
    availibility: "",
    price_low: "",
    price_high: "",
    neighborhood: "",
    unit_type: "",
    payment_plan: "",
    hand_over_date: "",
  };
  const [property, setProperty] = useState({
    offplan: offplanId,
    name: "",
    appartement_type: "",
    category: "",
    size: "",
    bedrooms: "",
    bathrooms: "",
    parkings: "",
    additional_features: "",
    description: "",
    status: "",
    availibility: "",
    price_low: "",
    price_high: "",
    neighborhood: "",
    unit_type: "",
    payment_plan: "",
    hand_over_date: "",
  });
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const handleClose = () => {
    setShow(false);
    setEdit(false);
    setEditImages([]);
    setImages("");
    setProperty(initialValue);
  };
  
  useEffect(() => {
    async function getAllProperties() {
      try {
        await axios
          .get(`${APP_CONFIG.backendUrl}api/offplan/name`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            console.log(res.data)
            setNames(res.data);
          });
      } catch (error) {
        showToast("could not fetch properties", "error");
      }
    }

    getAllProperties();
    console.log('we are in get again')
  }, [refresh]);

  async function createProperty() {
    try {
      let formData = new FormData();
      if (images.length > 0) {
        images.forEach((image) => formData.append("image", image));
        formData.append("name", property.name);
        formData.append("offplan", offplanId);
        formData.append("appartement_type", property.appartement_type);
        formData.append("additional_features", property.additional_features);
        formData.append("bathrooms", property.bathrooms);
        formData.append("bedrooms", property.bedrooms);
        formData.append("category", property.category);
        formData.append("description", property.description);
        formData.append("neighborhood", property.neighborhood);
        formData.append("unit_type", property.unit_type);
        formData.append("payment_plan", property.payment_plan);
        formData.append("hand_over_date", property.hand_over_date ? property?.hand_over_date : "Soon");
        formData.append("floor", property.floor);
        formData.append("price_low", property.price_low);
        formData.append("price_high", property.price_high);
        formData.append("parkings", property.parkings);
        formData.append("size", property.size);
      }
      if (edit === true) {
        await axios
          .put(
            `${baseUrl}/${property._id}`,
            images.length > 0 ? formData : { ...property, hand_over_date: property.hand_over_date ? property.hand_over_date : "Soon" },
            {
              headers: {
                "Content-Type":
                  images.length > 0
                    ? "multipart/form-data"
                    : "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              setLoading(false)
              showToast("property Updated successfully", "success");
              setShow(false);
              setEdit(false);
              setProperty(initialValue);
              setImages([]);
              getSubs(offplanId);
            } else {
              setLoading(false)

              showToast("Error! try again later ", "error");
            }
          });
      } else {
        await axios
          .post(baseUrl, images.length > 0 ? formData : { ...property, hand_over_date: property.hand_over_date ? property.hand_over_date : "Soon" }, {
            headers: {
              "Content-Type":
                images.length > 0 ? "multipart/form-data" : "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.status === 201) {
              setLoading(false)

              showToast("property Created successfully", "success");
              setShow(false);
              setProperty(initialValue);
              setImages([]);
              getSubs(offplanId);
            } else if (res.status === 409) {
              setLoading(false)

              showToast("Property name already exists !", "error");
            } else {
              setLoading(false)

              showToast("Error! try again later ", "error");
            }
          });
      }
    } catch (error) {
      if (error.response.status === 409) {
        setLoading(false)
        showToast("Property name already exists !", "error");
      } else {
        setLoading(false)

        showToast("Error! try again later ", "error");
      }
    }
  }
  async function deleteProperty(id) {
    try {
      console.log('we are in delete')
      await axios
        .delete(`${baseUrl}/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            showToast("property deleted successfully", "success");
            const updatedArray = data.filter(item => item._id !== id);
            setData(updatedArray);
          }
        });
    } catch (error) {
      showToast("Error in removeing property", "error");
    }
  }
  async function deleteSingleImage(imageId, propertyId) {
    try {
      setLoading(true)

      await axios
        .get(`${baseUrl}/image/${propertyId}/${imageId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setLoading(false)

            showToast("Image removed successfully", "success");
            const newImages = editImages.filter(
              (image) => image.public_id !== imageId
            );
            setEditImages(newImages);
          } else {
            setLoading(false)

            showToast("Error ! try again later", "error");
          }
        });
    } catch (error) {
      setLoading(false)

      showToast("Error ! try again later", "error");
    }
  }
  function editItem(item) {
    setEdit(true);
    setProperty(item);
    setEditImages(item.image);
    setShow(true);
  }
  async function getSubs(propertyId) {
    try {
      setOffplanId(propertyId);
      await axios
        .get(`${baseUrl}/sub/${propertyId}`, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => setData(res.data));
    } catch (error) {
      console.log(error);
    }
  }
console.log(data)
  useEffect(() => {
    setProperty(prevState => ({
      ...prevState,
      offplan: offplanId
    }));
  }, [offplanId]);

  return (
    <>
      <main className="buy-page-parent">
        <div className="property-page-container">
          <h1 className="users-page-title">Sub off plan Properties</h1>
          <div className="users-page-header-container">
            <div className="dropdown-container">
              <div
                className={
                  isDropdownOpen ? "filtering filtering_open" : "filtering"
                }
                onClick={handleDropdownToggle}
              >
                <span className="main-span-sub">
                  {dropdownName}
                  <i className="fa-solid fa-caret-down"></i>
                </span>
                {isDropdownOpen && (
                  <ul>
                    {names &&
                      names.map((offPlan, i) => (
                        <li
                          key={i}
                          onClick={() => {
                            getSubs(offPlan.id);
                            setDropdownName(offPlan.name);
                          }}
                        >
                          {offPlan.name}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            </div>
            {offplanId ? (
              <Button onClick={() => setShow(!show)}>Add</Button>
            ) : (
              ""
            )}
          </div>
          {data && data.length > 0 ? (
            <div className="properties_content">
              {data?.map((card, index) => (
                <div key={index} className="card-layer">
                  <img
                    alt="Delete icon"
                    src={dltIcon}
                    onClick={() => deleteProperty(card._id)}
                    className="delete-button"
                  />
                  <img
                    alt="edit icon"
                    src={editIcon}
                    onClick={() => editItem(card)}
                    className="edit-button"
                  />
                  <CategoryCard
                    location={card.location}
                    price1={card.price_low}
                    price2={card.price_high}
                    key={index}
                    name={card.name}
                    bedroom={card.bedrooms}
                    bathroom={card.bathrooms}
                    size={card.size}
                    id={card._id}
                    type={card.property_type}
                    image={card.image[0]}
                  />
                </div>
              ))}
            <div className="divider_spacer"></div>
            </div>
          ) : (
            <div className="no-cards">
              Choose Off plan property to access and create sub categories
            </div>
          )}
        </div>
        <SubForm
          show={show}
          onHide={handleClose}
          images={editImages}
          setImages={setImages}
          property={property}
          setProperty={setProperty}
          createProperty={createProperty}
          edit={edit}
          deleteImage={deleteSingleImage}
          setEdit={setEdit}
          loading={loading}
          setLoading={setLoading}
        />
      </main>
    </>
  );
};

export default Subpage;
