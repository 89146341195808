import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './testimonials.css';

const testimonials = [
  {
    id: 1,
    name: 'Sarah M.',
    text: `I had an amazing experience with AIM Real Estate! As a first-time homebuyer in Dubai, I was nervous, but their team guided me through every step. They listened to my preferences and found me the perfect property in no time. The process was smooth, and their attention to detail was impressive. Highly recommend AIM for anyone looking to buy in Dubai!`,
    image: 'testimonials6',
  },
  {
    id: 2,
    name: 'Khaled A.',
    text: `I recently rented an apartment through AIM Real Estate, and the service was outstanding. From the initial viewing to signing the contract, the team was professional, knowledgeable, and very responsive. They helped me find an apartment that fit my budget and needs perfectly. I’m extremely satisfied with their service.`,
    image: 'testimonials7',
  },
  {
    id: 3,
    name: 'Emily R.',
    text: `AIM Real Estate made buying my new villa in Dubai a breeze! They were transparent, patient, and always available to answer my questions. The team truly cares about their clients and goes the extra mile to ensure satisfaction. I’m thrilled with my new home and would definitely work with them again in the future.`,
    image: 'testimonials8',
  },
  {
    id: 4,
    name: 'Mohammed S.',
    text: `تجربتي كانت عنجد كتير حلوة! كنت عم دوّر على شقة للإيجار بدبي، والفريق كان كتير سريع بتقديم الخيارات اللي بتناسب احتياجاتي وميزانيتي. كانوا محترفين كتير وساعدوني خلّص العقد بسهولة وبدون أي مشاكل. بنصح الكل يتعامل معهم إذا بدهم عقار بدبي`,
    image: 'testimonials9',
  },
  {
    id: 5,
    name: 'Samar B.',
    text: `تعاملت معهم لما اشتريت أول شقة إلي بدبي، وكانت الخدمة من أولها لآخرها ممتازة. الفريق كان كتير متعاون وعطوني نصايح ساعدوني آخد القرار الصح. عندهم خبرة كبيرة بالسوق وسهلوا عليي كل الإجراءات. شكراً على الخدمة الرائعة`,
    image: 'testimonials10',
  },
  // Add more testimonials as needed
];

const Testimonials = () => {
  const [index, setIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    let interval;
    if (!isPaused) {
      interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
      }, 4000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isPaused, index]);

  const handleMouseEnter = () => setIsPaused(true);
  const handleMouseLeave = () => setIsPaused(false);

  return (
    <div className='testinomial-main'>
      <h2>What Our Clients Say</h2>
      <Carousel
        activeIndex={index}
        onSelect={(selectedIndex) => setIndex(selectedIndex)}
        className='testimonial-carousel'
      >
        {testimonials.map((testimonial) => (
          <Carousel.Item key={testimonial.id}>
            <div className='testimonial-item d-flex flex-column align-items-center text-center'>
              {/* <img
                className='testimonial-image'
                src={process.env.PUBLIC_URL + `/${testimonial.image}.png`}
                alt={testimonial.name}
              /> */}
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <h5>{testimonial.name}</h5>
                <p>{testimonial.text}</p>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
