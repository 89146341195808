import React, { useEffect, useRef, useState } from "react";
import "../category.css";
import CategoryCard from "../../../Components/CategoryCard/CategoryCard";
import axios from "axios";
import Pagination from "../../../Components/Pagination/Pagination";
import { APP_CONFIG } from "../../../config";

const OffPlanPage = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([]);
  const cardRef = useRef();
  const [cardData, setCardData] = useState(1);
  const cardsPerPage = 20;
  const totalPages = Math.ceil(data?.length / cardsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * cardsPerPage;
  const endIndex = Math.min(startIndex + cardsPerPage, data?.length);
  const startCardIndex = startIndex + 1;
  const endCardIndex = Math.min(endIndex, data?.length);
  const getRentData = async () => {
    try {
      setLoading(true)
      const rentResponse = await axios.get(`${APP_CONFIG.backendUrl}api/offplan`);
      setData(rentResponse.data);
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };
  useEffect(() => {
    getRentData();
  }, []);
  const handleNextPage = () => {
    cardRef.current.style.opacity = 0;
    setTimeout(() => {
      setCardData(cardData - 1);
      cardRef.current.style.opacity = 1;
      setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
    }, 300);
  };

  const handlePrevPage = () => {
    cardRef.current.style.opacity = 0;
    setTimeout(() => {
      setCardData(cardData + 1);
      cardRef.current.style.opacity = 1;
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    }, 300);
  };

  const handleFirstPage = () => {
    cardRef.current.style.opacity = 0;
    setTimeout(() => {
      setCardData(cardData + 1);
      cardRef.current.style.opacity = 1;
      setCurrentPage(0);
    }, 300);
  };

  return (
    <>
      <div
        className="properties_content offplaned"
        ref={cardRef}
        style={{ transition: "opacity 1s linear" }}
      >
        {data.length>0&&!loading ? (
          data
            ?.slice(startIndex, endIndex)
            .map((card, index) => (
              <CategoryCard
                location={card.address}
                name={card.name}
                key={index}
                id={card._id}
                image={card.image[0]}
                type={card.property_type}
              />
            ))
        ) : <div className="loader-container">
        {data.length===0&&!loading?<div style={{fontSize:"20px"}}>No properties found</div>:<>
        <div className="cube">
          <div className="face front"></div>
          <div className="face back"></div>
          <div className="face right"></div>
          <div className="face left"></div>
          <div className="face top"></div>
          <div className="face bottom"></div>
        </div>
        <p className="first_loading_p">Loading...</p>
        <p>Please wait</p>
        </> } </div>}
        <div className="divider_spacer"></div>
      </div>
      <Pagination
        handleFirstPage={handleFirstPage}
        currentPage={currentPage}
        handlePrevPage={handlePrevPage}
        totalPages={totalPages}
        handleNextPage={handleNextPage}
        startCardIndex={startCardIndex}
        endCardIndex={endCardIndex}
        datalength={data.length}
      />
    </>
  );
};

export default OffPlanPage;
